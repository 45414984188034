

// const urlHttp = "https://api.anr-data.mg/api/v1/";
const urlHttp = "https://personnel.gestion-bacc.gov.mg/api/v1/";
// const urlHttp = "http://localhost:9595/api/v1/";   

const API_CANDIDAT = urlHttp+ "candidat";
const API_LISTE_CANDIDAT = urlHttp+ "candidat/liste-candidat";

const url = {
    urlHttp,
    API_CANDIDAT,
    API_LISTE_CANDIDAT
}

export default url;