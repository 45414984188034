import { Link } from "react-router-dom";
import ProvinceC from "views/Centre_examen/ProvinceC";
import Nouveau_centre from "views/Nouveau_centre/Nouveau_centre";
import Specialite from "views/Serie/Specialite";
import ProvinceQG from "views/StatQG/ProvinceQG";
import ProvinceV from "views/Validation/ProvinceV";
import Dashboard from "views/dashboard/Dashboard.jsx";
// import ProvinceV from "views/Validation/ProvinceV";
import ProvinceDenombre from "views/Denombrement/Province";
import Calendrier from "views/Emplois_du_temps/Calendrier";
import Login from "views/candidats/Login";
import ListeCandidats from "views/candidats/listeCandidats";
import ListLogistiques from "views/logistiques/ListLogistiques";
import Nouveau_logistique from "views/logistiques/Nouveau_logistique";
import FormulairePersonnels from "views/personnels/Nouveau_personnel";
import ProvincePersonnelQG from "views/personnels/ProvinceQG";
import Page from "views/qr_code/Page";
import Prefecture from "views/prefecture/Prefecture";
import District from "views/prefecture/District";
import ListLogistique from "views/Urne/ListLogistique";
import Caa from "views/prefecture/Caa";
import ProvinceSujet from "views/sujet/ProvinceSujet";
import Presence from "views/presence/Presence";

const routes = [
  /* {
    id: 1,
    path: "#",
    name: "VOLET PLANIFICATION",
    icon: "",
    component: "",
    layout: "#",
    clickable: false,
    submenu: []
  }, */
  // {
  //   id: 1,
  //   path: "#",
  //   name: "ACCEUIL",
  //   icon: "",
  //   component: "",
  //   layout: "#",
  //   clickable: false,
  //   submenu: []

  // },

  {
    id: 1,
    path: "/dashboard",
    name: "Tableau de bord",
    icon: "nc-icon nc-layout-11",
    component: <Dashboard />,
    layout: "/admin",
    submenu: []
  },

  {
    id: 2,
    path: "/candidats/candidats",
    name: "Candidats",
    icon: "nc-icon nc-single-02",
    component: '',
    layout: "/admin",
    submenu: [
      <span>
        <Link to="/admin/ajout_candidat">Ajout de Candidat</Link>
      </span>,
      <span>
        <Link to="/admin/Candidat"> Candidats inscrits</Link>
      </span>,
      <span>
        <Link to="/admin/candidats/type-candidat">Types Candidat</Link>
      </span>,
      <span>
        <Link to="/admin/candidats/specialite">Spécialités</Link>
      </span>
    ],
  },
  {
    id: 3,
    path: "/centre-examen",
    name: "Centre d'examen",
    icon: "nc-icon nc-bank",
    component: '',
    layout: "/admin",
    submenu: [
      <span>
        <Link to="/admin/Nouveaucentre">Ajout de centre<br></br></Link>
      </span>,
      <span>
        <Link to="/admin/ListeCentre">Capacité des centres</Link>
      </span>,
      <span>
        <Link to="/admin/Ajout_personnel">Ajout de Personnel</Link>
      </span>,
      <span>
        <Link to="/admin/type_personnel">Effectif des Personnels</Link>
      </span>,
      <span>
        <Link to="/admin/logistiques/Ajout_Logistique">Ajout de Logistique</Link>
      </span>,
      <span>
        <Link to="/admin/logistiques/ListLogistiques">Logistiques</Link>
      </span>,
      <span>
        <Link to="/admin/Calendrier">Emplois du temps</Link>
      </span>,
      <span>
        <Link to="/admin/presence">Presence</Link>
      </span>
    ],
  },
  {
    id: 4,
    path: "/sujet",
    name: "Sujets",
    icon: "nc-icon nc-bookmark-2",
    component: '',
    layout: "/admin",
    submenu: [
      <span>
        <Link to="/admin/qr-code">QR Code<br></br></Link>
      </span>,
      <span>
        <Link to="/admin/list_log">Tracabilites<br></br></Link>
      </span>,
      <span>
        <Link to="#">Conclaves<br></br></Link>
      </span>,
      <span>
        <Link to="/admin/Denombrement">Suivi des Transcriptions</Link>
      </span>,
      <span>
        <Link to="/admin/envoi_sujet">Suivi des Envoie</Link>
      </span>
    ],
  },
  {
    id: 5,
    path: "/contact_re",
    name: "contact RE",
    icon: "nc-icon nc-single-02",
    component: '',
    layout: "/admin",
    submenu: [
      <span>
        <Link to="/admin/prefecture">Prefecture<br></br></Link>
      </span>,
      <span>
        <Link to="/admin/pref_dist">District<br></br></Link>
      </span>,
      <span>
        <Link to="/admin/Caa">CAA<br></br></Link>
      </span>
    ],
  },

  {
    id: 6,
    path: "/ajout_candidat",
    name: "",
    icon: "nc-icon nc-layout-11",
    component: <Login />,
    layout: "/admin",
    clickable: false,
    submenu: []
  },
  {
    id: 7,
    path: "/suivie-sujet",
    name: "Répartition Personnel ",
    icon: "nc-icon nc-single-02",
    component: <ListLogistique />,
    layout: "/admin",
  },
  {
    id: 8,
    path: "/type_personnel",
    name: "Répartition Personnel ",
    icon: "nc-icon nc-single-02",
    component: <ProvincePersonnelQG />,
    layout: "/admin",
  },
  {
    id: 9,
    path: "/qr-code",
    name: "QR code génération",
    icon: "nc-icon nc-single-02",
    component: <Page />,
    layout: "/admin",
  },
  {
    id: 10,
    path: "/logistiques/ListLogistiques",
    name: "Répartition logistique ",
    icon: "nc-icon nc-single-02",
    component: <ListLogistiques />,
    layout: "/admin",
  },
  {
    id: 11,
    path: "/candidats/validation",
    name: "Répartition des validation ",
    icon: "nc-icon nc-book-bookmark",
    component: <ProvinceV />,
    clickable: false,
    layout: "/admin"
  },
  {
    id: 12,
    path: "/candidats/type-candidat",
    name: "Types des candidats",
    icon: "nc-icon nc-touch-id",
    component: <ProvinceQG />,
    clickable: false,
    layout: "/admin"
  },
  {
    id: 13,
    path: "/candidats/specialite",
    name: "Répartition des spécialités ",
    icon: "nc-icon nc-single-02",
    component: <Specialite />,
    clickable: false,
    layout: "/admin"
  },
  {
    id: 14,
    path: "/Candidat",
    name: "",
    icon: "nc-icon nc-single-02",
    component: <ListeCandidats />,
    clickable: false,
    layout: "/admin"
  },
  {
    id: 15,
    path: "/Nouveaucentre",
    name: "Ajout de centre",
    icon: "nc-icon nc-single-02",
    component: <Nouveau_centre />,
    layout: "/admin"
  },
  {
    id: 16,
    path: "/ListeCentre",
    name: "Capacité d'acceuil des centres  ",
    icon: "nc-icon nc-layout-11",
    component: <ProvinceC />,
    layout: "/admin"
  },
  {
    id: 17,
    path: "/ListeValidation",
    name: "Validation Candidat libre",
    icon: "nc-icon nc-single-02",
    component: <ProvinceV />,
    layout: "/admin"
  },
  {
    id: 18,
    path: "/Calendrier",
    name: "",
    icon: "nc-icon nc-single-02",
    component: <Calendrier />,
    layout: "/admin"
  },
  {
    id: 19,
    path: "/Ajout_personnel",
    name: "Ajout de personnel",
    icon: "nc-icon nc-single-02",
    component: <FormulairePersonnels />,
    clickable: false,
    layout: "/admin"
  },
  {
    id: 20,
    path: "/Denombrement",
    name: "",
    icon: "nc-icon nc-single-02",
    component: <ProvinceDenombre />,
    layout: "/admin"
  },
  {
    id: 21,
    path: "/logistiques/Ajout_Logistique",
    name: "",
    icon: "nc-icon nc-single-02",
    component: <Nouveau_logistique />,
    layout: "/admin"
  },
  {
    id: 22,
    path: "/prefecture",
    name: "contact RE Préfécture",
    icon: "nc-icon nc-single-02",
    component: <Prefecture />,
    layout: "/admin"
  },
  {
    id: 23,
    path: "/pref_dist",
    name: "contact RE district",
    icon: "nc-icon nc-single-02",
    component: <District />,
    layout: "/admin"
  },
  {
    id: 24,
    path: "/list_log",
    name: "Repartition des Urnes",
    icon: "nc-icon nc-single-02",
    component: <ListLogistique />,
    layout: "/admin"
  },
  {
    id: 25,
    path: "/caa",
    name: "contact RE CAA",
    icon: "nc-icon nc-single-02",
    component: <Caa />,
    layout: "/admin"
  },
  {
    id: 26,
    path: "/envoi_sujet",
    name: "SUIVI ENVOI DES SOBIKA",
    icon: "nc-icon nc-single-02",
    component: <ProvinceSujet />,
    layout: "/admin"
  },
  {
    id: 27,
    path: "/presence",
    name: "SUIVI DES PRESENCEs",
    icon: "nc-icon nc-single-02",
    component: <Presence />,
    layout: "/admin"
  },
  // {
  //   id:39,
  //   path: "/goodies",
  //   name: "Goodies",
  //   icon: "nc-icon nc-cart-simple",
  //   component: <Goodies/>,
  //   layout: "/admin",
  //   submenu: []
  // },

];

export default routes;
