import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardTitle, CardBody, Table, Button, CardHeader, FormGroup, Label, Input } from 'reactstrap';
import url from 'urlConfig';
import RegionStatQG from './RegionStatQG';
import ANRDataUtils from 'utils/Utils';
import './../scrollbar.css';
import authHeader from 'services/AuthHeader';
import Legende from './Legende';

const ProvinceSujet = () => {
    console.log("ATOOOOOOOOOOOOOOO")
    const auth_data = authHeader()
    const [loader, setLoader] = useState(false);
    const [lists, setLists] = useState([]);
    const [province, setProvince] = useState("");
    const [dateE, setDateE] = useState(new Date());
    const [etatE, setEtatE] = useState(1);
    const [dateR, setDateR] = useState(new Date());
    const [etatR, setEtatR] = useState(1);

    const [list, setList] = useState([]);
    const getProvinces = () => {
        let bodyy = {
            province: "",
            region: "",
            district: "",
            commune: "",
            centre_examen: "",
            date_envoyer: "",
            date_recue: "",
            etet_recue: "",
            etet_envoyer: ""
        }
        setLoader(true);
        fetch(url.urlHttp + "envoyer/liste?view=envoyer_province", {
            method: "POST",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(bodyy)
        }).then(response => response.json())
            .then((res) => {
                setList(res.data);
                setLists(res.data);
            })
            .catch(error => { console.log(error) })
    }

    const update = (bodyy) => {
        setLoader(true);
        fetch(url.urlHttp + "envoyer/update?view=envoyer_province", {
            method: "POST",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(bodyy)
        }).then(response => response.json())
            .then((res) => {
                getProvinces();
            })
            .catch(error => { console.log(error) })
    }
    const filtre = (value) => {
        const filteredData = lists.filter(item => item.province.toUpperCase().includes(value.toUpperCase()));
        setList(filteredData)
    }


    const [visibleRegion, setVisibleRegion] = useState(false);
    const [province_sec, setprovince_sec] = useState(null)

    const detailProvince = (value) => {
        setprovince_sec(value)
        setVisibleRegion(true)
    }

    const envoyer = (value, value1, value2, value3) => {
        console.log(value + "+" + value1 + "+" + value2 + "+" + value3);
        let bodyy = null
        if (value === 1) {
            bodyy = {
                province: value1,
                region: "",
                district: "",
                commune: "",
                centre_examen: "",
                date_envoyer: dateE,
                date_recue: dateR,
                etet_recue: 0,
                etet_envoyer: 1
            }
        } else {
            if (value3 === 1) {
                bodyy = {
                    province: value1,
                    region: "",
                    district: "",
                    commune: "",
                    centre_examen: "",
                    date_envoyer: value2,
                    date_recue: dateR,
                    etet_recue: 1,
                    etet_envoyer: 1
                }
            } else {
                bodyy = {
                    province: value1,
                    region: "",
                    district: "",
                    commune: "",
                    centre_examen: "",
                    date_envoyer: dateE,
                    date_recue: dateR,
                    etet_recue: 1,
                    etet_envoyer: 1
                }
            }

        }
        console.log(bodyy);
        update(bodyy);
    }

    useEffect(() => {
        getProvinces();
    }, [])

    if (visibleRegion == true && province_sec != null) {
        return (
            <>
                <RegionStatQG setVisibleRegion={setVisibleRegion} province={province_sec} />
            </>
        )
    } else {
        return (
            <div className="content">
                <Row>
                    <Col className="pr-1" md="4"><br />
                        <FormGroup>
                            <label ><a style={{ width: '60px', color: 'green', fontWeight: "bold", fontSize: '1.1rem' }}>Province :</a></label>
                            <input
                                type={'text'}
                                className={'form-control'}
                                placeholder={'rechercher province'}
                                onChange={e => filtre(e.target.value)}
                            />
                        </FormGroup><br />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardHeader>
                                    <CardTitle>
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "20px" }}> Au niveau National</span>
                                    </CardTitle>
                                    <div>
                                        <h3 style={{ color: 'red' }}>Veuillez saisir la date avant de faire une action</h3>
                                    </div>
                                    <Col className="pr-3" md="6">
                                        <FormGroup className="mr-3">
                                            <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>DATE D ENVOI</a> :</Label>
                                            <Input
                                                placeholder=""
                                                type="date"
                                                value={dateE} onChange={e => { setDateE(e.target.value) }}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col className="pr-3" md="6">
                                        <FormGroup className="mr-3">
                                            <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>DATE DE RECEPTION</a> :</Label>
                                            <Input
                                                placeholder=""
                                                type="date"
                                                value={dateR} onChange={e => { setDateR(e.target.value) }}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </CardHeader>
                                <div>
                                    <Legende />
                                </div>
                                <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                                    <Table style={{ marginTop: "-20px" }} striped>
                                        <thead className="text-primary sticky-header">
                                            <tr>
                                                <th style={{ fontSize: '12px' }} >Province</th>
                                                <th style={{ fontSize: '12px' }} >Envoyer</th>
                                                <th style={{ fontSize: '12px' }} >Date d'envoi </th>
                                                <th style={{ fontSize: '13px' }} >Recue</th>
                                                <th style={{ fontSize: '12px' }} >Date de reception</th>
                                                <th style={{ fontSize: '12px' }} >Action </th>
                                                <th style={{ fontSize: '12px' }} >Action </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((row) => {
                                                    let couleur = '';
                                                    if (row.etet_envoyer === 0 && row.etet_recue === 0) {
                                                        couleur = 'red';
                                                    }
                                                    if (row.etet_envoyer === 1 && row.etet_recue === 0) {
                                                        couleur = 'yellow';
                                                    }
                                                    if (row.etet_envoyer === 1 && row.etet_recue === 1) {
                                                        couleur = 'rgb(94, 199, 56)';
                                                    }
                                                    return (
                                                        <>
                                                            <tr style={{ backgroundColor: couleur }}>
                                                                <td >
                                                                    {row.province}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {row.etet_envoyer == 0 ? 'non' : 'oui'}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {row.date_envoyer}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {row.etet_recue == 0 ? 'non' : 'oui'}
                                                                </td>
                                                                <td style={{ fontSize: '1.5rem' }}>
                                                                    {row.etet_recue == 0 ? '' : row.date_recue}
                                                                </td>
                                                                <td style={{ fontSize: '1.2rem' }}>
                                                                    {row.etet_envoyer == 0 ? <button className={'btn btn-danger'} onClick={() => { envoyer(1, row.province, row.date_envoyer, row.etet_envoyer) }}>Sur Route</button>
                                                                        : <button className={'btn btn-danger disabled'}>Sur Route</button>}
                                                                </td>
                                                                <td style={{ fontSize: '1.2rem' }}>
                                                                    {row.etet_recue == 0 ? <button className={'btn btn-danger'} onClick={() => { envoyer(2, row.province, row.date_envoyer, row.etet_envoyer) }}>Envoyé</button>
                                                                        : <button className={'btn btn-danger disabled'}>Envoyé</button>}
                                                                </td>
                                                                <td >
                                                                    {
                                                                        <button className={'btn btn-danger'} onClick={(e) => { detailProvince(row.province) }}>Afficher Region</button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProvinceSujet;