import { React, useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, FormGroup, CardTitle, CardBody, Table, Label, Input, Button, Form } from 'reactstrap';
import url from "urlConfig";
import authHeader from 'services/AuthHeader';
import ANRDataUtils from 'utils/Utils';
import './scrollbar.css';

const Presence = () => {
    const auth_data = authHeader();
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState('');
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    const [inscrit, setInscrit] = useState('');
    const [present, setPresent] = useState('');
    const [absent, setAbsent] = useState('');
    const [date_presence, setDate_presence] = useState(null);
    const [list, setList] = useState([]);
    const [lists, setLists] = useState([]);

    const getProvinces = () => {
        fetch(url.urlHttp + "tanana/province", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA", data.data);
                setProvinces(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRegion = () => {
        fetch(url.urlHttp + "tanana/region?region=" + province, {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA REG", data.data);
                setRegions(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDistrict = () => {
        fetch(url.urlHttp + "tanana/district?region=" + region + "&&district=", {
            method: 'GET',
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("DATA DIST", data.data);
                setDistricts(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const listes = () => {
        let bodyy = {
            province: province,
            region: region,
            cisco: district,
            inscrit: "500",
            present: "450",
            absent: "50",
            date_presence: date_presence
        }
        fetch(url.urlHttp + "presence/liste", {
            method: "POST",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(bodyy)
        }).then(response => response.json())
            .then((res) => {
                setList(res.data);
                setLists(res.data);
                console.log(bodyy);
            })
            .catch(error => { console.log(error) })
    }

    const nouveau = () => {
        let bodyy = {
            province: province,
            region: region,
            cisco: district,
            inscrit: inscrit,
            present: present,
            absent: absent,
            date_presence: date_presence
        }
        fetch(url.urlHttp + "presence/nouveau", {
            method: "POST",
            headers: {
                'Content-Type': auth_data.content_type,
                'Authorization': auth_data.authorization
            },
            body: JSON.stringify(bodyy)
        }).then(response => response.json())
            .then((res) => {
                listes();
            })
            .catch(error => { console.log(error) })
    }

    const [milahatra, setMilahatra] = useState(true);
    const handleSort = (order, key) => {
        const sortedWords = [...lists]; // Faites une copie pour éviter de muter l'état directement
        if (milahatra == true) {
            sortedWords.sort((a, b) => {
                if (typeof a[key] === 'string') {
                    if (order === 'asc') {
                        return a[key].localeCompare(b[key]); // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key].localeCompare(a[key]); // Trie par ordre décroissant
                    }
                } else if (typeof a[key] === 'number') {
                    if (order === 'asc') {
                        return a[key] - b[key]; // Trie par ordre croissant
                    } else if (order === 'desc') {
                        return b[key] - a[key]; // Trie par ordre décroissant
                    }
                }
                return 0;
            });
            setMilahatra(false);
            setList(sortedWords);
        } else {
            setMilahatra(true);
            setList(lists);
        }
        console.log(milahatra);
    };

    useEffect(() => {
        getProvinces();
        listes();
    }, [])

    const save = (value) => {
        if (value == 1) {
            nouveau();
        } else {
            listes();
        }
    }
    return (
        <>
            <Card style={{ marginTop: "100px", marginLeft: "20px", marginRight: "20px" }} >
                <CardHeader></CardHeader>
                <CardTitle tag="h3" className="d-flex mt-3 justify-content-center font-weight-bold" style={{ color: "green" }}>
                    NOUVEAU PRESENCE
                </CardTitle>
                <CardBody>
                    <Form >
                        <Row>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>PROVINCE :</a></label><br />
                                    <select className={'form-control'} value={province} onChange={e => { setProvince(e.target.value) }} onClick={handleRegion}>
                                        <option value={''}></option>
                                        {provinces?.map(province => (
                                            <option value={province.province} >{province.province}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>RÉGION : </a> </label><br />
                                    <select className={'form-control'} value={region} onChange={e => { setRegion(e.target.value) }} onClick={handleDistrict}>
                                        <option value={''}></option>
                                        {regions?.map(region => (
                                            <option value={region.region} >{region.region}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-4" md="4">
                                <FormGroup className="mr-3">
                                    <label style={{ border: 'type-round' }} ><a style={{ color: "black", fontWeight: 'bold' }}>DISTRICT :</a></label><br />
                                    <select className={'form-control'} value={district} onChange={e => { setDistrict(e.target.value) }}>
                                        <option value={''}></option>
                                        {districts?.map(district => (
                                            <option value={district.district} >{district.district}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="4">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>DATE DU PRESENCE</a> :</Label>
                                    <Input
                                        placeholder="Adresse"
                                        type="date"
                                        value={date_presence} onChange={e => { setDate_presence(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="4">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>CANDIDAT INSCRIT :</a></Label>
                                    <Input
                                        placeholder="candidat inscrit"
                                        type="number"
                                        value={inscrit} onChange={e => { setInscrit(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="4">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>CANDIDAT PRESENT :</a></Label>
                                    <Input
                                        placeholder="candidat present"
                                        type="number"
                                        value={present} onChange={e => { setPresent(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="4">
                                <FormGroup className="mr-3">
                                    <Label for="fonctionDem"><a style={{ color: "black", fontWeight: 'bold' }}>CANDIDAT ABSENT :</a></Label>
                                    <Input
                                        placeholder="candidat absent"
                                        type="number"
                                        value={absent} onChange={e => { setAbsent(e.target.value) }}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className="btn btn-primary" onClick={(e) => save(1)}>
                            Enregistrer
                        </Button>
                        <Button className="btn btn-primary" onClick={(e) => save(2)}>
                            Rechercher
                        </Button>
                    </Form>
                    <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto", direction: "" }}>

                        <Table style={{ marginTop: "-20px" }} striped>
                            <thead className="text-primary sticky-header">
                                <tr>
                                    <th style={{ fontSize: '14px' }} onClick={() => handleSort('asc', 'cisco')}>Cisco&nbsp;<i class="fa fa-arrow-down-a-z"></i></th>
                                    <th style={{ fontSize: '14px' }} onClick={() => handleSort('asc', 'date_presence')}>Date du&nbsp;<i class="fa fa-arrow-down-1-9"></i></th>
                                    <th style={{ fontSize: '12px' }} >Inscrit</th>
                                    <th style={{ fontSize: '13px' }} >Present</th>
                                    <th style={{ fontSize: '12px' }} >Absent</th>
                                    <th style={{ fontSize: '12px' }} >% Present </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((row) => (
                                        <>
                                            <tr>
                                                <td >
                                                    {row.cisco}
                                                </td>
                                                <td style={{ fontSize: '1.5rem' }}>
                                                    {row.date_presence}
                                                </td>
                                                <td style={{ fontSize: '1.5rem' }}>
                                                    {ANRDataUtils.formatNombre(row.inscrit)}
                                                </td>
                                                <td style={{ fontSize: '1.5rem' }}>
                                                    {ANRDataUtils.formatNombre(row.present)}
                                                </td>
                                                <td style={{ fontSize: '1.5rem' }}>
                                                    {ANRDataUtils.formatNombre(row.absent)}
                                                </td>
                                                <td style={{ fontSize: '1.2rem' }}>
                                                    {(((row.present / row.inscrit)) * 100).toFixed(2)}%
                                                </td>
                                            </tr>
                                        </>
                                    )
                                    )}
                            </tbody>
                        </Table>
                    </div>
                    {/* </div> */}
                </CardBody>
            </Card>
        </>
    );
}
export default Presence;